import Checkbox from "@/Components/Checkbox";
import { Button } from "@/Components/ui/button";
import { Card, CardContent } from "@/Components/ui/card";
import { Input } from "@/Components/ui/input";
import { ScrollArea } from "@/Components/ui/scroll-area";
import { Sheet, SheetContent, SheetFooter, SheetHeader, SheetTitle } from "@/Components/ui/sheet";
import { encryptArray, navigate, setLoader } from "@/Hooks/Functions";
import { ConfirmDialog } from "@/lib/mixins";
import { cn } from "@/lib/utils";
import { Icon } from "@iconify/react";
import { Link, usePage } from "@inertiajs/react";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useDebounceFn } from "ahooks";
import { sumBy } from 'lodash';
import React, { useCallback, useMemo, useState } from "react";
import { toast } from "sonner";
import Swal from "sweetalert2";
import Empty from "./Empty";
import { useGlobalModals } from "@state/store";

const EachCartItem = React.memo(({ item, selected = [], onDelete, onQtyChange, onSelect }) => {
    const [qty, setQty] = useState(item.quantity);

    const increment = useCallback(() => {
        setQty(qty => qty + 1);
        onQtyChange(item.id, qty + 1);
    }, [qty]);

    const decrement = useCallback(() => {
        if (qty > 1) {
            setQty(qty => qty - 1);
            onQtyChange(item.id, qty - 1);
        }
    }, [qty]);

    return (
        <Card className="w-full mb-1 shadow-xl bg-[#f2f2f2]">
            <CardContent className="p-2 flex relative flex-row isolate items-start gap-y-2 gap-x-3">
                <label htmlFor={`item-${item.id}`} className="flex w-fit cursor-pointer items-center">
                    <Checkbox id={`item-${item.id}`} value={item.id} checked={selected.includes(item.id)} onChange={() => onSelect(item.id)} className="z-[40] absolute left-4 top-4" />
                    <img src={item.cart.mockup_image_url} className="aspect-auto rounded-sm overflow-hidden shadow-none max-h-[130px]" alt="Product mockup" style={{ backgroundColor: item.cart.product_color }} />
                </label>
                <div className="flex w-full flex-1 flex-col items-start">
                    <p className='text-sm mb-2 font-semibold'>{item.cart?.product?.title}</p>
                    <div className="grid w-full grid-cols-2">
                        <div className="col-span-1 flex flex-col justify-center gap-1">
                            <div className="flex flex-row gap-2 items-center">
                                <span className="text-xs">Size:</span>
                                <span className="text-sm font-semibold">{item.variant?.size}</span>
                            </div>
                            <div className="flex flex-row gap-2 items-center">
                                <span className="text-xs">Color:</span>
                                <span className="text-sm font-semibold">{item.variant?.color}</span>
                            </div>
                            <div className="flex flex-row gap-2 items-center">
                                <span className="text-xs">Price:</span>
                                <span className="text-sm font-semibold">${item.total_price}</span>
                            </div>
                        </div>
                        <div className="col-span-1 flex flex-col justify-center items-center flex-grow h-full gap-4">
                            <Button variant="destructive-outline" className="w-fit px-4" size="xs" onClick={() => onDelete(item)}>
                                <Icon icon="mingcute:delete-line" className="text-xl cursor-pointer hover:text-destructive font-semibold" />
                                Remove
                            </Button>
                            <div className='w-full text-sm flex flex-row gap-2 justify-center items-center font-semibold'>
                                <Icon onClick={decrement} icon="mdi:minus-circle-outline" className={cn('text-xl  font-semibold', qty <= 1 ? 'cursor-not-allowed' : 'cursor-pointer hover:text-cyan-600')} />
                                <Input value={qty} className="w-12 text-center" readOnly data-tooltip-id="rmp-tooltip" data-tooltip-content="Quantity" />
                                <Icon onClick={increment} icon="mdi:plus-circle-outline" className={cn('text-xl font-semibold', qty >= 500 ? 'cursor-not-allowed' : 'cursor-pointer hover:text-cyan-600')} />
                            </div>
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
});

const fetchCartItems = async (page) => {
    const response = await window.axios.get(route('cartitems.paginate', { page: page, per_page: 10 }));
    return response.data;
};

export default function ShoppingCart({ iconClass }) {
    const { auth } = usePage().props;

    const setShowCart = useGlobalModals(state => state.setShoppingCart);
    const showCart = useGlobalModals(state => state.shoppingCart);

    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(1);
    const queryClient = useQueryClient();

    const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } = useInfiniteQuery({
        queryKey: ['cartitems'],
        queryFn: ({ pageParam = 1 }) => fetchCartItems(pageParam),
        getNextPageParam: (lastPage) => {
            // Check if there are more pages to load
            if (lastPage.current_page < lastPage.last_page) {
                return lastPage.current_page + 1;
            }
            return undefined;
        },
        enabled: !!auth.user
    });

    const deleteMutation = useMutation({
        mutationFn: async ({ itemId }) => {
            setLoader(true);
            return await window.axios.delete(route('cart.delete-item', { id: itemId }));
        },
        onSuccess: () => {
            setLoader(false);
            queryClient.invalidateQueries(['carts']);
            toast.success("Cart item deleted successfully");
        },
        onError: () => {
            setLoader(false);
            toast.error("Failed to delete cart item");
        }
    });
    const quantityMutation = useMutation({
        mutationFn: ({ itemId, qty }) => {
            setLoader(true);
            return window.axios.put(route('cart.update-item-quantity', { id: itemId }), { quantity: qty });
        },
        onSuccess: () => {
            setLoader(false);
            queryClient.invalidateQueries(['carts']);
            toast.success("Cart item updated successfully");
        },
        onError: () => {
            setLoader(false);
            toast.error("Failed to update cart item");
        }
    });
    const items = useMemo(() => {
        return data?.pages.reduce((acc, page) => {
            return [...acc, ...page.data];
        }, []) || [];
    }, [data]);
    const selectedItems = useMemo(() => items.filter(i => selected.includes(i.id)), [items, selected]);
    const selectedTotal = useMemo(() => sumBy(selectedItems, (i) => parseFloat(i.total_price)), [selectedItems]);

    const loadMore = useCallback(() => {
        if (hasNextPage) {
            fetchNextPage();
        }
    }, [fetchNextPage, hasNextPage]);

    const toggleSelected = useCallback((itemId) => {
        setSelected(prev => prev.includes(itemId) ? prev.filter(i => i !== itemId) : [...prev, itemId]);
    }, []);

    const toggleSelectAll = useCallback((e) => {
        setSelected(e.target.checked ? items.map(i => i.id) : []);
    }, [items]);

    const deleteCartItem = useCallback((itemId) => {
        ConfirmDialog.fire({
            title: 'Confirmation required!',
            text: "Remove this item from cart?",
            confirmButtonText: "Yes! Proceed",
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            if (result.isConfirmed) {
                deleteMutation.mutate({ itemId });
            }
        });
    }, [deleteMutation]);

    const { run: onQtyChange } = useDebounceFn((itemId, qty) => {
        quantityMutation.mutate({ itemId, qty });
    }, { wait: 500 })

    const proceedCheckout = useCallback(async () => {
        if (selected.length <= 0) {
            toast.error("Please select at least one item");
            return;
        }
        const val = await encryptArray(selected);
        setShowCart(false);
        navigate(route('cart.checkout-shipping', { carts: val }), 'GET');
    }, [selected, setShowCart]);

    return (
        <>
            <Button tip="View Shopping Cart" variant="link" className="!p-1 group" onClick={() => setShowCart(true)}>
                <Icon icon="el:shopping-cart-sign" className={cn("h-8 w-8 group-hover:text-cyan-600 text-primary", iconClass, items.length > 0 ? "animate-pulse" : "animate-none")} />
            </Button>
            <Sheet open={showCart} onOpenChange={() => setShowCart(false)}>
                {auth?.user ? <SheetContent className="!z-[999] p-0 flex flex-col gap-4 md:min-w-[450px] min-w-full">
                    <SheetHeader className="gap-4 px-4 py-2 justify-between flex flex-row !items-center">
                        {items.length > 0 && <label className="inline-flex items-center gap-2 text-sm font-semibold">
                            <Checkbox disabled={!items.length} onChange={toggleSelectAll} checked={selected.length === items.length} /> Select All
                        </label>}
                        <SheetTitle className="mr-8 font-nunito italic">Shopping Cart</SheetTitle>
                    </SheetHeader>
                    <ScrollArea className="rounded-lg h-[calc(100vh-100px)] text-primary w-full px-2">
                        {isLoading && <div>Loading...</div>}
                        {!isLoading && !items.length && <Empty className="min-h-[80vh]" text="No cart found!" />}
                        {items.map((item) => (
                            <EachCartItem
                                key={item.id}
                                item={item}
                                selected={selected}
                                onQtyChange={onQtyChange}
                                onDelete={deleteCartItem}
                                onSelect={toggleSelected}
                            />
                        ))}
                        {hasNextPage && (
                            <Button
                                variant="outline"
                                className="w-full mt-4"
                                onClick={loadMore}
                                disabled={isFetchingNextPage}
                            >
                                {isFetchingNextPage ? 'Loading...' : 'Load More'}
                            </Button>
                        )}
                    </ScrollArea>
                    {items.length > 0 && <SheetFooter className="py-3 px-4 flex w-full flex-row !justify-between items-center">
                        <div className="flex flex-row justify-center items-center gap-1">
                            <span className="text-sm font-semibold">{selected.length}</span>
                            <span className="text-xs font-semibold">Selected</span>
                        </div>
                        <div className="flex flex-row justify-center items-center gap-1">
                            <span className="text-xs font-semibold">Total : </span>
                            <span className="text-sm font-semibold">$ {selectedTotal.toFixed(2)}</span>
                        </div>
                        <Button onClick={proceedCheckout} disabled={!selected.length} variant="theme">Proceed To Checkout</Button>
                    </SheetFooter>}
                </SheetContent> :
                    <SheetContent className="!z-[999] p-0 flex flex-col gap-4 md:min-w-[450px] min-w-full">
                        <SheetHeader className="gap-4 px-4 py-2 justify-between flex flex-row !items-center">
                            <SheetTitle className="mr-8">Shopping Cart</SheetTitle>
                        </SheetHeader>
                        <ScrollArea className="rounded-lg h-[calc(100vh-100px)] text-primary w-full px-2">
                            <Empty className="min-h-[80vh]" text="Please login to view cart!" >
                                <Link href={route('login')} >
                                    <Button className="mt-4 px-8">Login</Button>
                                </Link>
                            </Empty>
                        </ScrollArea>
                    </SheetContent>}
            </Sheet>
        </>
    );
}
